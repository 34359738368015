import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout/index.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`MCQ’s Books`}</h1>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1qcel9kdI8bke3HZHfCMVUk3qVERJP34X"
      }}>{`Download Civil engineering conventional and objective type by R.S. Khurmi and J.S. Gupta`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1VfW4OgEzcNclriWCWgHYeliPNgo_Qji2"
      }}>{`Download: Khanna’s objective and conventional questions and answers on civil engineering by R. Agor`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=16qHlQ_M5QH5SL6fSC74mXQ1O0GnAiRQ8"
      }}>{`Download: Civil engineering formulas by Tyler G. Hicks`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=18eaSeFEg4TKn_9DRUHxotOLoFa7k7kQ4"
      }}>{`Download: Civil engineering MCQ’S by R. Agor`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=19CcUI5ziQE6hCGw18F8Zk8qwkBhgdtU8"
      }}>{`Download: Civil engineering MCQ’S by Imran Ahmad Bhatti`}</a></p>
    <h1>{`Subject wise mcq’s`}</h1>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/basic-civil-engineering-sanfoundry.com_.pdf"
      }}>{`Basic civil engineering sanfoundry.com`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Geotechnical-engineering-sanfoundry.com_.pdf"
      }}>{`Geotechnical engineering sanfoundry.com`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/highway-engineering-sanfoundry.com_.pdf"
      }}>{`Highway engineering sanfoundry.com`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/strength-of-materials-sanfoundry.com_.pdf"
      }}>{`Strength of materials sanfoundry.com`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/concrete-mcqs.pdf"
      }}>{`Concrete mcq’s`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/SK-Garg-irrigation-mcqs.pdf"
      }}>{`SK Garg irrigation mcq’s`}</a></p>
    <h1>{`Subject wise mcq’s`}</h1>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Water-Supply-Engineering.pdf"
      }}>{`Water Supply Engineering`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Waste-water-Engineering.pdf"
      }}>{`Waste water Engineering`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Theory-of-Structures.pdf"
      }}>{`Theory of Structures`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Surveying-and-Levelling.pdf"
      }}>{`Surveying and Levelling`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Structural-Analysis.pdf"
      }}>{`Structural Analysis`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Strength-of-Materials.pdf"
      }}>{`Strength of Materials`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Soil-Mechanics-and-Foundation-Engineering.pdf"
      }}>{`Soil Mechanics and Foundation Engineering`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Remote-Sensing.pdf"
      }}>{`Remote Sensing`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/RCC-Structures-Design.pdf"
      }}>{`RCC Structures Design`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Railway-Engineering.pdf"
      }}>{`Railway Engineering`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Irrigation-Engineering.pdf"
      }}>{`Irrigation Engineering`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Hydrology-and-Water-Resources-Engineering.pdf"
      }}>{`Hydrology and Water Resources Engineering`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Hydraulics-engineering.pdf"
      }}>{`Hydraulics engineering`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Highway-Engineering.pdf"
      }}>{`Highway Engineering`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Estimating-and-Costing.pdf"
      }}>{`Estimating and Costing`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Docks-and-Harbour-Engineering.pdf"
      }}>{`Docks and Harbour Engineering`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Design-of-steel-structures.pdf"
      }}>{`Design-of-steel-structures`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Design-of-Masonry-Structures.pdf"
      }}>{`Design of Masonry Structures`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Construction-Planning-and-Management.pdf"
      }}>{`Construction Planning and Management`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Concrete-Technology-and-Design.pdf"
      }}>{`Concrete Technology and Design`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Building-Materials.pdf"
      }}>{`Building Materials`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Building-Construction.pdf"
      }}>{`Building Construction`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Airport-engineering-MCQs-PDF.pdf"
      }}>{`Airport engineering-MCQs-PDF`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Advanced-surveying-MCQs-PDF.pdf"
      }}>{`Advanced-surveying-MCQs-PDF`}</a></p>
    <h1>{`Subject wise mcq’s`}</h1>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/water-supply-engineering.pdf"
      }}>{`water supply engineering`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Water-resources-engineering.pdf"
      }}>{`Water resources engineering`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Waste-water-engineering.pdf"
      }}>{`Waste water engineering`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Theory-of-structures.pdf"
      }}>{`Theory of structures`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Strength-of-material-indibix.pdf"
      }}>{`Strength of material indiabix`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Steel-structures.pdf"
      }}>{`Steel structures`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/soil-mechanics-and-foundation-engineering.pdf"
      }}>{`soil mechanics and foundation engineering`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/RCC-Structure-Design-indiabix.pdf"
      }}>{`RCC Structure Design indiabix`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Railway-engineering.pdf"
      }}>{`Railway engineering`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Irrigation-engineering.pdf"
      }}>{`Irrigation engineering`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Hydraulics-engineering-1.pdf"
      }}>{`Hydraulics engineering`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/highway-engineering.pdf"
      }}>{`highway engineering`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Estimating-and-costing.pdf"
      }}>{`Estimating and costing`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Construction-management.pdf"
      }}>{`Construction management`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Concrete-technology.pdf"
      }}>{`Concrete technology`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Building-materials.pdf"
      }}>{`Building materials`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Building-construction.pdf"
      }}>{`Building construction`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Applied-mechanics.pdf"
      }}>{`Applied mechanics`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      